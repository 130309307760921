// @ts-nocheck
import { createTheme } from '@mui/material/styles'
import WebFont from '../webfontloader'

WebFont.load({
  typekit: {
    id: 'yja8ugj',
  },
})

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1200,
      lg: 1280,
      xl: 1920,
    },
  },
})

theme = createTheme(theme, {
  palette: {
    primary: {
      main: '#1A3A45',
    },
    secondary: {
      main: '#F3F0ED',
    },
    error: {
      main: '#891A1A',
    },
    divider: '#393838',
    errorRed: {
      main: '#891A1A',
    },

    darkBackgroundText: {
      main: '#F7D8BA',
    },
    darkbackgroundTextHover: {
      main: '#E7C9AC',
    },

    teal: {
      main: '#1A3A45',
    },
    tealHover: {
      main: '#235161',
    },
    teal6: {
      main: '#edfbff',
    },
    teal3: {
      main: '#09161a',
    },
    teal15: {
      main: '#1A3A4515',
    },
    bioBlue: {
      main: '#47639C',
    },
    white: {
      main: '#FFFFFF',
    },

    gray1: {
      main: '#F3F0ED',
    },
    gray3: {
      main: '#CFCCC9',
    },
    gray5: {
      main: '#666666',
    },
    gray7: {
      main: '#4A4A4A',
    },
    gray8: {
      main: '#393838',
    },
    gray9: {
      main: '#393838',
    },

    progressComplete: {
      main: '#C9DADB',
    },
    progressCompleteText: {
      main: '#82878C',
    },
    progressCurrent: {
      main: '#1A3A45',
    },
    progressCurrentText: {
      main: '#1A3A45',
    },
    progressFuture: {
      main: '#1A3A4525',
    },
    progressFutureText: {
      main: '#393838',
    },

    disclaimer: {
      main: '#666666',
    },

    text: {
      main: '#1A3A45',
    },
    darkBackground: {
      main: '#1A3A45',
    },
    lightBackground: {
      main: '#1A3A4512',
    },
    logo: {
      main: '#1A3A45',
    },
    graphPrimary: {
      main: '#1A3A45',
    },
    graphSecondary: {
      main: '#1A3A4564',
    },
    link: {
      main: '#2b6a80',
    },
    linkHover: {
      main: '#235161',
    },
    sidebarHighlight: {
      main: '#F7D8BA',
    },
    mapHighlight: {
      main: '#1A3A45',
    },
    sliderBackground: {
      main: '#1A3A4564',
    },
    sliderButton: {
      main: '#1A3A45',
    },
    spinner: {
      main: '#1A3A45',
    },
  },
})

theme.shadows[8] = '0px 8px 24px rgba(69, 73, 77, 0.08)'

theme = createTheme(theme, {
  typography: {
    fontFamily: `"degular"`,
    h1: {
      fontFamily: 'freight-big-pro',
      fontSize: '4.25rem',
      fontWeight: 500,
      lineHeight: '4.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
        lineHeight: '2.75rem',
      },
    },
    h2: {
      fontFamily: 'freight-big-pro',
      fontSize: '3.25rem',
      fontWeight: 500,
      lineHeight: '125%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
      },
    },
    h3: {
      fontFamily: 'degular',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    h4: {
      fontFamily: 'degular',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
    },
    p0: {
      fontFamily: 'degular',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    p1: {
      fontFamily: 'degular',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
    p1m: {
      fontFamily: 'degular',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    p2: {
      fontFamily: 'degular',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    p3: {
      fontFamily: 'degular',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    p4: {
      fontFamily: 'degular',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      lineHeight: '1rem',
    },
    c1: {
      fontFamily: 'degular',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    c2: {
      fontFamily: 'degular',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    h1m: {
      fontFamily: 'degular',
      fontSize: '2.5rem',
      fontWeight: 500,
      lineHeight: '2.75rem',
    },
    h2m: {
      fontFamily: 'degular',
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '2.5rem',
    },

    body1: {
      fontFamily: 'degular',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
    },
    cta: {
      fontFamily: 'degular',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    button: {
      fontFamily: 'degular',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      textTransform: 'none',
    },
    disclaimer: {
      fontFamily: 'degular',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1rem',
    },
    l1: {
      fontFamily: 'degular',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },

    // Disabled variants
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
})

theme = createTheme(theme, {
  sidebarOptions: {
    selectedTitleFontVariant: 'h2',
    unselectedTitleFontVariant: 'h4',
    selectedDescriptionFontVariant: 'p1',
    unselectedDescriptionFontVariant: 'p2',
  },
  mapboxOverrides: {
    circleColor: theme.palette.teal.main,
  },
  pillSelectorOptions: {
    selectedBackgroundColor: '#1A3A4515',
  },
  formSidebarOptions: {
    fontVariant: 'h3',
    fontWeight: 500,
  },
})

const mobileButtonStyles = {
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '8px',
  ...(theme.typography.cta as any),
}

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'p1',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'primary',
        size: 'large',
      },
      styleOverrides: {
        root: {
          border: `2px solid ${theme.palette.white.main}10`,
          transition:
            'border-color 0.1s linear, outline 0.1s linear, color 0.1s linear, background-color 0.1s linear, box-shadow 0.1s ease',
          borderRadius: '8px',

          // Default colors
          backgroundColor: theme.palette.teal.main,
          color: theme.palette.white.main,

          '&:hover': {
            backgroundColor: theme.palette.tealHover.main,
          },

          '&:active': {
            color: theme.palette.teal,
            backgroundColor: theme.palette.tealHover.main,
          },

          '&.Mui-disabled': {
            color: theme.palette.gray3.main,
            backgroundColor: theme.palette.gray5.main,
          },
          '&:focus': {
            boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.25)`,
          },
        },
      },
      variants: [
        {
          props: { variant: 'primaryDark' },
          style: {
            backgroundColor: theme.palette.white.main,
            border: `2px solid ${theme.palette.white.main}`,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.gray1.main,
            },
          },
        },
        {
          props: { variant: 'secondaryDark' },
          style: {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.teal.main,
            border: `2px solid ${theme.palette.teal.main}`,
            padding: '0px!important',
            '&:hover': {
              color: `${theme.palette.white.main}98`,
              backgroundColor: theme.palette.teal.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.teal.main,
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: theme.palette.teal.main,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.tealHover.main,
            },

            '&:active': {
              color: theme.palette.white.main,
              backgroundColor: theme.palette.tealHover.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray3.main,
              backgroundColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: theme.palette.teal.main,
            backgroundColor: `${theme.palette.white.main}50`,
            border: `2px solid ${theme.palette.teal.main}`,
            '&:hover': {
              backgroundColor: theme.palette.tealHover.main,
            },

            '&:active': {
              color: theme.palette.teal.main,
              backgroundColor: theme.palette.tealHover.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray5.main,
              backgroundColor: theme.palette.gray3.main,
              borderColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            backgroundColor: theme.palette.teal.main,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.tealHover.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.tealHover.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray3.main,
              backgroundColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            ...(theme.typography.p1 as any),
            width: '100%',
            padding: '8px 16px',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...(theme.typography.cta as any),
            padding: '10px 30px',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'largeForm' },
          style: {
            ...(theme.typography.cta as any),
            width: 'inherit',
            padding: '12px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta as any),
              padding: '16px 32px',
            },
          },
        },
        {
          props: { size: 'smallForm' },
          style: {
            fontWeight: 'unset',
            ...(theme.typography.cta as any),
            width: 'inherit',
            padding: '8px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta as any),
              padding: '8px 32px',
            },
          },
        },
        {
          props: { size: 'mini' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 24px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 24px',
            },
          },
        },
        {
          props: { size: 'micro' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 16px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 16px',
            },
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },

          '& .MuiMenu-paper': {
            padding: '24px 0px',
            borderRadius: '24px',
          },

          '& .MuiMenuItem-root': {
            padding: '8px 24px',
            color: theme.palette.teal.main,
            ...(theme.typography.p1 as any),
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            padding: '16px',
            borderRadius: '24px',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-root': {
            marginTop: '4px',
            marginBottom: '4px',
          },

          '& .MuiPickersDay-root': {
            ...(theme.typography.c1 as any),
            color: theme.palette.gray7.main,
            border: `1px solid white`,
            transition: 'border-color 200ms ease',

            '&:hover': {
              color: theme.palette.teal.main,
              borderColor: theme.palette.teal.main,
              backgroundColor: 'unset',
            },

            '&:focus': {
              color: theme.palette.teal.main,
              borderColor: theme.palette.teal.main,
              backgroundColor: 'unset',
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.tealHover.main,
              color: theme.palette.white.main,

              '&:hover': {
                backgroundColor: theme.palette.tealHover.main,
                color: theme.palette.teal.main,
                borderColor: theme.palette.teal.main,
              },

              '&:focus': {
                backgroundColor: theme.palette.tealHover.main,
                color: theme.palette.white.main,
              },
            },
          },

          '& .PrivatePickersYear-yearButton': {
            ...(theme.typography.c1 as any),
            color: theme.palette.gray7.main,
            border: `1px solid white`,
            transition: 'border-color 200ms ease',

            '&:hover': {
              color: theme.palette.teal.main,
              borderColor: theme.palette.teal.main,
              backgroundColor: 'unset',
            },

            '&:focus': {
              color: theme.palette.teal.main,
              borderColor: theme.palette.teal.main,
              backgroundColor: 'unset',
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.tealHover.main,
              color: theme.palette.teal.main,

              '&:hover': {
                backgroundColor: theme.palette.tealHover.main,
                color: theme.palette.teal.main,
                borderColor: theme.palette.teal.main,
              },

              '&:focus': {
                backgroundColor: theme.palette.tealHover.main,
                color: theme.palette.teal.main,
              },
            },
          },

          '& .MuiDayPicker-weekDayLabel': {
            ...(theme.typography.c1 as any),
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        size: 'large',
        label: '',
        helperText: '',
        InputProps: {
          notched: false,
        },
      },
      styleOverrides: {
        root: {
          paddingTop: '24px',
          '& .MuiInputBase-root': {
            ...(theme.typography.p1m as any),
            borderRadius: '8px',
            transition: 'background-color 200ms ease, box-shadow 200ms ease',
            backgroundColor: theme.palette.white.main,
            color: theme.palette.teal.main,
            '&:hover': {
              backgroundColor: '#F7F7F7',
            },
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.15)`,
            backgroundColor: theme.palette.white.main,
          },
          '& .MuiFormLabel-root': {
            ...(theme.typography.p1m as any),
            transform: 'translate(24px, 48px) scale(1)',
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
            color: theme.palette.gray5.main,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(24px, 0px) scale(1)',
            ...theme.typography.p3,
            color: theme.palette.teal.main,
          },
          '& .MuiInputBase-input': {
            padding: '12px 24px',
            minHeight: '48px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            paddingLeft: '28px',
            paddingRight: '28px',
            border: `2px solid ${theme.palette.teal.main}`,
          },
          '& .MuiFormHelperText-root': {
            ...theme.typography.p3,
            marginLeft: '24px',
            marginRight: '24px',
            marginBottom: '0px',
            fontWeight: '300',
          },

          '& .MuiInputBase-adornedEnd': {
            paddingRight: '12px',
          },

          '& .Mui-error': {
            '& .MuiInputAdornment-root': {
              '& .MuiButtonBase-root': {
                backgroundColor: theme.palette.error.main,
              },
            },
          },
          '& .MuiSelect-icon': {
            top: 'calc(50% - 12px)',
          },
        },
      },
      variants: [
        {
          props: { label: '' },
          style: {
            paddingTop: '0px',
          },
        },
        {
          props: { multiline: true },
          style: {
            '& .MuiInputBase-input': {
              padding: '12.5px 10px',
              ...theme.typography.p1,
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-input': {
              padding: '11px 24px 13px 24px',
              minHeight: '29px',
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 38px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              color: theme.palette.gray5.main,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              color: theme.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            '& .MuiFilledInput-root': {
              '&::before': {
                borderBottom: 'none',
              },
              '&::after': {
                borderBottom: 'none',
              },
              '&:hover:not(.Mui-disabled)': {
                borderBottom: 'none',
                '&:before': {
                  borderBottom: 'none',
                },
                '&:after': {
                  borderBottom: 'none',
                },
              },
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 48px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              color: theme.palette.teal.main,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              color: theme.palette.teal.main,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.teal.main,
            },
            '& .MuiInputBase-input': {
              padding: '12px 24px',
              color: theme.palette.teal.main,
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
              // color: theme.palette.teal.main,

              '& .Mui-error': {
                color: theme.palette.error.main,
              },
            },
          },
        },
        {
          props: { variant: 'filled', multiline: true },
          style: {
            '& .MuiFilledInput-root': {
              '&::before': {
                borderBottom: 'none',
              },
              '&::after': {
                borderBottom: 'none',
              },
              '&:hover:not(.Mui-disabled)': {
                borderBottom: 'none',
                '&:before': {
                  borderBottom: 'none',
                },
                '&:after': {
                  borderBottom: 'none',
                },
              },
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 48px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              color: theme.palette.teal.main,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              color: theme.palette.teal.main,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.teal.main,
            },
            '& .MuiInputBase-root': {
              paddingTop: '4px',
            },
            '& .MuiInputBase-input': {
              padding: '12px 24px',
              color: theme.palette.teal.main,
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
              // color: theme.palette.teal.main,

              '& .Mui-error': {
                color: theme.palette.error.main,
              },
            },
          },
        },
        {
          props: { variant: 'filled', color: 'secondary' },
          style: {
            '& .MuiFilledInput-root': {
              backgroundColor: '#2A416D!important',

              '&:hover': {
                backgroundColor: '#2A416D',
              },
              '&::before': {
                borderBottom: 'none',
              },
              '&::after': {
                borderBottom: 'none',
              },
              '&:hover:not(.Mui-disabled)': {
                borderBottom: 'none',
                '&:before': {
                  borderBottom: 'none',
                },
                '&:after': {
                  borderBottom: 'none',
                },
              },
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 48px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              color: theme.palette.white.main,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              color: theme.palette.white.main,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.white.main,
            },
            '& .MuiInputBase-input': {
              padding: '12px 24px',
              color: theme.palette.white.main,
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
              color: theme.palette.white.main,
            },
          },
        },
        {
          props: { size: 'mini' },
          style: {
            '& .MuiInputBase-root': {
              borderRadius: '18px',
            },
            '& .MuiInputBase-input': {
              padding: '4px 16px',
              ...theme.typography.c1,
            },
            '& .MuiInputBase-inputMultiline': {
              padding: '0px',
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 36px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p3,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.c1,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.c1,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
            },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: '8px',
            },
          },
        },
        {
          props: { size: 'dropdownMini' },
          style: {
            '& .MuiInputBase-root': {
              '& .MuiSelect-icon': {
                right: '8px',
              },
              borderRadius: '36px',
              boxShadow: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '6px 12px',
              ...theme.typography.p3,
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 36px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p3,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.c1,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.c1,
              marginLeft: '24px',
              marginRight: '12px',
              marginBottom: '0px',
            },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: '0px',
            },
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          transition: 'background-color 200ms linear, color 200ms linear',
          color: theme.palette.link.main,
          fontWeight: 500,
          cursor: 'pointer',
          width: 'fit-content',

          '&:hover': {
            color: theme.palette.linkHover.main,
          },

          '&:active': {
            color: theme.palette.linkHover.main,
          },
        },
      },
      variants: [
        {
          props: { variant: 'cta' },
          style: {
            color: theme.palette.white.main,
            '&:hover': {
              color: `${theme.palette.white.main}98`,
            },
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButtonGroup-grouped': {
            '&:not(:first-of-type)': {
              borderLeft: `1px solid ${theme.palette.teal.main}`,
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.tealHover.main,
            '&:hover': {
              backgroundColor: theme.palette.tealHover.main,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.white.main,
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        icon: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          borderRadius: '0rem',
          paddingTop: 0,
          paddingBottom: 0,
          '& .MuiAlert-message': {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          '& .MuiAlert-action': {
            marginLeft: 'unset',
            alignItems: 'center',
            paddingTop: 0,
            [theme.breakpoints.down('sm')]: {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        icon: false,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          borderRadius: '0rem',
          backgroundColor: 'unset',
          padding: '0px',
          '&:before': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          borderBottom: `3px solid ${theme.palette.gray9.main}`,
          ...theme.typography.h4,
          '& .MuiTypography-root': {
            ...theme.typography.p2,
            color: theme.palette.gray9.main,
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.2rem',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          backgroundColor: 'white',
          paddingLeft: '72px',
          paddingRight: '72px',
          height: '80px',
          color: 'red',
          transition: 'background-color 500ms ease',

          [theme.breakpoints.down('md')]: {
            height: 'fit-content',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: '1px',
          borderColor: theme.palette.gray7.main,
          width: '1px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.gray7.main,
            width: '8px',
            right: 'unset',
          },

          '& .MuiTypography-root': {
            color: theme.palette.teal.main,
            transition: 'font-size 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.tealHover.main,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.gray7.main,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { boxShadow: theme.shadows[8] },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: { boxShadow: theme.shadows[8] },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          letterSpacing: 'inherit',
          lineHeight: 'inherit',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.teal.main,
          fontSize: '0.875rem',
          lineHeight: '150%',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '36px',
          height: '20px',
          padding: '0px',
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 0,
            color: theme.palette.teal.main,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: theme.palette.teal.main,
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.link.main,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            width: 20,
            height: 20,
            borderRadius: 32,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
          },
          '& .MuiSwitch-track': {
            borderRadius: 32,
            opacity: 1,
            backgroundColor: theme.palette.gray3.main,
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
})

export const adminScholasticTheme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'primary',
        size: 'large',
      },
      styleOverrides: {
        root: {
          border: `2px solid ${theme.palette.white.main}10`,
          transition:
            'border-color 0.1s linear, outline 0.1s linear, color 0.1s linear, background-color 0.1s linear, box-shadow 0.1s ease',
          borderRadius: '8px',

          // Default colors
          backgroundColor: theme.palette.teal.main,
          color: theme.palette.white.main,

          '&:hover': {
            backgroundColor: theme.palette.tealHover.main,
          },

          '&:active': {
            color: theme.palette.white,
            backgroundColor: theme.palette.tealHover.main,
          },

          '&.Mui-disabled': {
            color: theme.palette.gray3.main,
            backgroundColor: theme.palette.gray5.main,
          },
          '&:focus': {
            boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.25)`,
          },
        },
      },
      variants: [
        {
          props: { variant: 'primaryDark' },
          style: {
            backgroundColor: theme.palette.white.main,
            border: `2px solid ${theme.palette.white.main}`,
            color: theme.palette.teal.main,

            '&:hover': {
              backgroundColor: theme.palette.gray1.main,
            },
          },
        },
        {
          props: { variant: 'secondaryDark' },
          style: {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.teal.main,
            border: `2px solid ${theme.palette.teal.main}`,
            padding: '0px!important',
            '&:hover': {
              color: `${theme.palette.white.main}98`,
              backgroundColor: theme.palette.teal.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.teal.main,
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: theme.palette.teal.main,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.tealHover.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.tealHover.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray3.main,
              backgroundColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: theme.palette.teal.main,
            backgroundColor: `${theme.palette.white.main}50`,
            border: `2px solid ${theme.palette.teal.main}`,
            '&:hover': {
              backgroundColor: `${theme.palette.gray3.main}`,
            },

            '&:active': {
              color: theme.palette.teal.main,
              backgroundColor: `${theme.palette.gray3.main}`,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray5.main,
              backgroundColor: theme.palette.gray3.main,
              borderColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            backgroundColor: theme.palette.teal.main,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.teal.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.teal.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray3.main,
              backgroundColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            ...(theme.typography.p1 as any),
            width: '100%',
            padding: '8px 16px',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...(theme.typography.cta as any),
            padding: '10px 30px',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'largeForm' },
          style: {
            ...(theme.typography.cta as any),
            width: 'inherit',
            padding: '12px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta as any),
              padding: '16px 32px',
            },
          },
        },
        {
          props: { size: 'smallForm' },
          style: {
            fontWeight: 'unset',
            ...(theme.typography.cta as any),
            width: 'inherit',
            padding: '8px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta as any),
              padding: '8px 32px',
            },
          },
        },
        {
          props: { size: 'mini' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 24px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 24px',
            },
          },
        },
        {
          props: { size: 'micro' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 16px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 16px',
            },
          },
        },
      ],
    },
  },
})

const scholasticTheme = theme

export default scholasticTheme
