import React, { useState, useMemo, ChangeEvent, useCallback } from 'react'
import {
  DataTable,
  LoadingCard,
  useSnackbar,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { TextField, Typography } from '@mui/material'
import { debounce } from 'lodash'
import { useQuery } from '@apollo/client'
import {
  AdminSearchCustomersDocument,
  Core_Customer,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useFundConfig } from '@flock/shared-ui'

import {
  CustomCustomerRowRender,
  customerColumns,
  formatCustomer,
} from './CustomerRowRender'

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',

  '& > div > input': {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

type CustomerManagementPageProps = {
  path: string
}

const CustomerManagementPage = (props: CustomerManagementPageProps) => {
  const { path } = props
  const { notify } = useSnackbar()
  const { offerUrl } = useFundConfig()

  let querySearch = ''
  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)
    querySearch = params.get('search') || ''
    querySearch = querySearch.toLowerCase()
  }

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    customRowRender: CustomCustomerRowRender(path, notify, offerUrl),
    elevation: 0,
  }

  const [searchString, setSearchString] = useState(querySearch)

  const { loading, data } = useQuery(AdminSearchCustomersDocument, {
    variables: {
      searchCustomersInput: {
        searchString,
      },
    },
    onError: () => {
      notify('Failed to get customers', 'error')
    },
  })

  const customers: Core_Customer[] =
    data?.searchCustomers?.customers?.map(formatCustomer as any) || []

  customers.sort((a, b) => a.createdAt - b.createdAt)

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h2">Customer Management</Typography>
      </PageTitleContainer>
      <SearchWrapper>
        <SearchField
          placeholder="Search customers"
          defaultValue={querySearch}
          onChange={debounceSetSearchStringToSearchFieldInput}
          data-cy="customerSearchField"
        />
      </SearchWrapper>
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <DataTable
          title=""
          data={customers}
          columns={customerColumns}
          options={options as any}
        />
      )}
    </>
  )
}

export default CustomerManagementPage
