import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { useFirebaseAuth } from '@flock/utils'
import * as Sentry from '@sentry/gatsby'
import { TENANT } from '../constants'

type ConfiguredApolloProviderProps = {
  children: React.ReactNode
}
const ConfiguredApolloProvider = ({
  children,
}: ConfiguredApolloProviderProps) => {
  const apolloLinks = []

  const { user } = useFirebaseAuth()

  const errorLink = onError((errResult) => {
    const { operation, graphQLErrors, networkError } = errResult
    const context = operation.getContext()
    const traceId = context.response?.headers?.get('X-Amzn-Trace-Id')
    Sentry.captureException(
      new Error(`GQL Operation failed: ${operation.operationName}`),
      {
        tags: {
          traceId,
        },
        extra: {
          variables: JSON.stringify(operation.variables, null, 2),
          graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
          networkError: JSON.stringify(networkError, null, 2),
        },
      }
    )
  })
  apolloLinks.push(errorLink)

  const tenantLink = setContext(async (_, previousContext) => ({
    headers: {
      ...previousContext.headers,
      tenant: TENANT,
    },
  }))
  apolloLinks.push(tenantLink)

  const authorizationLink = setContext(async (_, previousContext) => {
    // If this token will expire in less than an hour, refresh the token
    const token = await user?.getIdToken()
    return {
      headers: {
        ...previousContext.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })
  apolloLinks.push(authorizationLink)

  const uploadLink = createUploadLink({ uri: process.env.GATSBY_APOLLO_URL })
  apolloLinks.push(uploadLink)

  const client = new ApolloClient({
    link: from(apolloLinks as ApolloLink[]),
    cache: new InMemoryCache(),
  })

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ConfiguredApolloProvider
