import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputType } from '@flock/shared-ui'
import * as Yup from 'yup'
import {
  AdminGetLeadDocument,
  AdminUpdateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import GridFormModal from '../shared/GridFormModal'

// yup validation overrides the rules of react-hook-form
// including when an input config is required, so should define required here
const validationSchema = Yup.object().shape({
  overrideOnboardingFee: Yup.boolean(),
  feeOverride: Yup.string().when(
    ['overrideOnboardingFee', 'waiveOnboardingFee'],
    {
      is: (overrideOnboardingFee: boolean, waiveOnboardingFee: boolean) =>
        overrideOnboardingFee && !waiveOnboardingFee,
      then: Yup.string()
        .required('Required field')
        .matches(/^0.0[0-6](\d+)?$/, 'Fee should be less than 0.07'),
    }
  ),
  brokerCommission: Yup.string().when('overrideBrokerCommission', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Commission should be a decimal representing a percentage of the offer price.'
      ),
  }),
  closingCosts: Yup.string().when('overrideClosingCosts', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Closing costs should be a decimal representing a percentage of the offer price.'
      ),
  }),
  leadNameOverride: Yup.string().when('overrideLeadName', {
    is: true,
    then: Yup.string().required('Lead name is required'),
  }),
  // https://github.com/jquense/yup/issues/549#issuecomment-855698808
  // value is number if numbers exist in it, string if you click on textfield but is empty,
  // null if you don't interact with it at all
  cashTakeout: Yup.string().when('overrideCashTakeout', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  rentReduction: Yup.string().when('overrideRentReduction', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  daysInRemodelDeduction: Yup.string().when('overrideDaysInRemodelDeduction', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  offerDisclaimer: Yup.string().when('overrideOfferDisclaimer', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  addressDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  cityStateZipDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
})

const inputConfigs = [
  {
    name: 'overrideLeadName',
    type: InputType.Checkbox,
    props: {
      label: 'Override Lead Name',
    },
  },
  {
    name: 'leadNameOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideLeadName,
    props: {
      label: 'Lead Name Override',
      format: 'text',
    },
  },
  {
    name: 'overrideOnboardingFee',
    type: InputType.Checkbox,
    props: {
      label: 'Override Onboarding Fee',
    },
  },
  {
    name: 'waiveOnboardingFee',
    type: InputType.Checkbox,
    renderIf: (watchedFields: any) => !!watchedFields.overrideOnboardingFee,
    props: {
      label: 'Waive Onboarding Fee',
    },
  },
  {
    name: 'feeOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) =>
      !watchedFields.waiveOnboardingFee &&
      !!watchedFields.overrideOnboardingFee,
    props: {
      label: 'Fee Override (enter as a decimal)',
      format: 'text',
    },
  },
  {
    name: 'overrideBrokerCommission',
    type: InputType.Checkbox,
    props: {
      label: 'Override Broker Commission',
    },
  },
  {
    name: 'brokerCommission',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideBrokerCommission,
    props: {
      label: 'Broker Commission (enter as a decimal)',
      format: 'text',
    },
  },
  {
    name: 'overrideClosingCosts',
    type: InputType.Checkbox,
    props: {
      label: 'Override Closing Costs',
    },
  },
  {
    name: 'closingCosts',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideClosingCosts,
    props: {
      label: 'Closing Costs (enter as a decimal)',
      format: 'text',
    },
  },

  {
    name: 'overrideCashTakeout',
    type: InputType.Checkbox,
    props: {
      label: 'Override Cash Takeout',
    },
  },
  {
    name: 'cashTakeout',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideCashTakeout,
    props: {
      label: 'Cash Takeout',
      format: 'number',
    },
  },
  {
    name: 'overrideRentReduction',
    type: InputType.Checkbox,
    props: {
      label: 'Override Rent Reduction',
    },
  },
  {
    name: 'rentReduction',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideRentReduction,
    props: {
      label: 'Rent Reduction',
      format: 'number',
    },
  },
  {
    name: 'overrideDaysInRemodelDeduction',
    type: InputType.Checkbox,
    props: {
      label: 'Override Days in Remodel Deduction',
    },
  },
  {
    name: 'daysInRemodelDeduction',
    type: InputType.Text,
    renderIf: (watchedFields: any) =>
      !!watchedFields.overrideDaysInRemodelDeduction,
    props: {
      label: 'Days In Remodel Deduction',
      format: 'number',
    },
  },
  // {
  //   name: 'overrideOfferDisclaimer',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Offer Disclaimer',
  //   },
  // },
  // {
  //   name: 'offerDisclaimer',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.overrideOfferDisclaimer,
  //   props: {
  //     label: 'Offer Disclaimer Override',
  //     format: 'text',
  //   },
  // },
  {
    name: 'overrideAddress',
    type: InputType.Checkbox,
    props: {
      label: 'Override Address',
    },
  },
  {
    name: 'addressDisplayOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideAddress,
    props: {
      label: 'Street Address Override',
      format: 'text',
    },
  },
  {
    name: 'cityStateZipDisplayOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideAddress,
    props: {
      label: 'City, State, Zip Display Override',
      format: 'text',
    },
  },
]

type EditOverridesParams = {
  waiveOnboardingFee: boolean
  overrideOnboardingFee: boolean
  overrideLeadStatus: boolean
  leadStatusOverride: string
  overrideLeadName: boolean
  leadNameOverride: string
  setCapexCosts: boolean
  capexCosts: string
  feeOverride: string
  overrideAddress: boolean
  addressDisplayOverride: string
  cityStateZipDisplayOverride: boolean
  overrideGraphYears: boolean
  yearsToProject: number

  overrideOfferDisclaimer: boolean
  offerDisclaimer: string
  overrideCashTakeout: boolean
  cashTakeout: string
  overrideClosingCosts: boolean
  closingCosts: string
  overrideBrokerCommission: boolean
  brokerCommission: string
  overrideRentReduction: boolean
  rentReduction: string
  overrideDaysInRemodelDeduction: boolean
  daysInRemodelDeduction: string
  portfolioNameOverride: string
  portfolioAssetCountOverride: string
  portfolioDoorCountOverride: string
  portfolioCityStateOverride: string
  portfolioSFRDoorsOverride: string
  portfolioMultifamilyDoorsOverride: string
  overridePortfolioName: boolean
  overridePortfolioAssetCount: boolean
  overridePortfolioDoorCount: boolean
  overridePortfolioCityState: boolean
  overridePortfolioSFRDoors: boolean
  overridePortfolioMultifamilyDoors: boolean
}

type EditLeadOverridesModalProps = {
  open: boolean
  onClose: () => void
  leadUuid: string
  overrides: { [key: string]: any }
}

const EditLeadOverridesModal = (props: EditLeadOverridesModalProps) => {
  const { open, onClose, leadUuid, overrides } = props
  const [loading, setLoading] = useState(false)
  const [updateLead] = useMutation(AdminUpdateLeadDocument)

  const { notify } = useSnackbar()

  const onSubmit = async (editOverridesParams: EditOverridesParams) => {
    const {
      waiveOnboardingFee,
      overrideOnboardingFee,
      feeOverride,
      overrideLeadStatus,
      leadStatusOverride,
      overrideLeadName,
      leadNameOverride,
      setCapexCosts,
      capexCosts,
      overrideAddress,
      addressDisplayOverride,
      cityStateZipDisplayOverride,
      overrideGraphYears,
      yearsToProject,

      overrideOfferDisclaimer,
      offerDisclaimer,
      overrideCashTakeout,
      cashTakeout,
      overrideRentReduction,
      rentReduction,
      overrideDaysInRemodelDeduction,
      daysInRemodelDeduction,
      overrideClosingCosts,
      closingCosts,
      overrideBrokerCommission,
      brokerCommission,
      portfolioNameOverride,
      portfolioAssetCountOverride,
      portfolioDoorCountOverride,
      portfolioCityStateOverride,
      portfolioSFRDoorsOverride,
      portfolioMultifamilyDoorsOverride,
      overridePortfolioName,
      overridePortfolioAssetCount,
      overridePortfolioDoorCount,
      overridePortfolioCityState,
      overridePortfolioSFRDoors,
      overridePortfolioMultifamilyDoors,
    } = editOverridesParams

    const result: { [key: string]: any } = {
      ...overrides,
      waiveOnboardingFee,
      overrideOnboardingFee,
      overrideAddress,
      overrideGraphYears,
      offerDisclaimer,
      overrideCashTakeout,
      overrideRentReduction,
      overrideDaysInRemodelDeduction,
      overrideOfferDisclaimer,
      overrideClosingCosts,
      overrideBrokerCommission,
      overrideLeadStatus,
      overrideLeadName,
      overridePortfolioName,
    }

    if (overrideOnboardingFee && !waiveOnboardingFee) {
      result.feeOverride = parseFloat(feeOverride)
    }

    if (overrideCashTakeout) {
      result.cashTakeout = parseInt(cashTakeout, 10)
    }

    if (overrideRentReduction) {
      result.rentReduction = parseInt(rentReduction, 10)
    }

    if (overrideDaysInRemodelDeduction) {
      result.daysInRemodelDeduction = parseInt(daysInRemodelDeduction, 10)
    }

    if (overrideClosingCosts) {
      result.closingCosts = parseFloat(closingCosts)
    }

    if (overrideBrokerCommission) {
      result.brokerCommission = parseFloat(brokerCommission)
    }

    if (overrideLeadStatus) {
      result.leadStatusOverride = leadStatusOverride
    }

    if (overrideLeadName) {
      result.leadNameOverride = leadNameOverride
    }

    if (overridePortfolioName) {
      result.portfolioName = portfolioNameOverride
    }

    if (overridePortfolioAssetCount) {
      result.portfolioAssetCount = parseInt(portfolioAssetCountOverride, 10)
    }

    if (overridePortfolioDoorCount) {
      result.portfolioDoorCount = parseInt(portfolioDoorCountOverride, 10)
    }

    if (overridePortfolioCityState) {
      result.portfolioCityState = portfolioCityStateOverride
    }

    if (overridePortfolioSFRDoors) {
      result.portfolioSFRDoors = parseInt(portfolioSFRDoorsOverride, 10)
    }

    if (overridePortfolioMultifamilyDoors) {
      result.portfolioMultifamilyDoors = parseInt(
        portfolioMultifamilyDoorsOverride,
        10
      )
    }

    if (setCapexCosts) {
      result.capexCosts = parseInt(capexCosts, 10)
    }

    if (overrideAddress) {
      result.addressDisplayOverride = addressDisplayOverride
      result.cityStateZipDisplayOverride = cityStateZipDisplayOverride
    }

    if (overrideGraphYears) {
      result.yearsToProject = yearsToProject
    }

    setLoading(true)
    try {
      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            overrides: JSON.stringify(result),
          },
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully updated lead overrides.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update lead overrides.', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      open={open}
      onClose={onClose}
      loading={loading}
      onSubmit={onSubmit}
      title="Edit lead overrides"
      formProps={{
        defaultValues: {
          ...overrides,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
      }}
      inputConfigs={inputConfigs}
    />
  )
}

export default EditLeadOverridesModal
