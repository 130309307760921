import { AppBar, Toolbar, Box } from '@mui/material'
import React from 'react'
import NavItem from '../Navbar/NavItem'
import TrackedButton from '../TrackedButton/TrackedButton'
import TrackedLink from '../TrackedLink/TrackedLink'
import { ExchangeNavbarPresentationalProps } from './exchangeNavbarTypes'

const ExchangeNavbarDesktop = (props: ExchangeNavbarPresentationalProps) => {
  const { onClick, navItems, actions, logo, logoWidthToHeight } = props

  const Logo = logo

  return (
    <AppBar
      position="sticky"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'darkBackground.main',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '1120px',

          paddingLeft: '0px!important',
          paddingRight: '0px!important',
        }}
      >
        <Box display="flex" alignItems="center" gap="28px">
          <TrackedLink
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': { backgroundColor: 'unset' },
            }}
            onClick={onClick}
          >
            <Logo width={logoWidthToHeight * 25} height={25} color="white" />
          </TrackedLink>
        </Box>

        <Box display="flex" alignItems="center" gap="16px">
          <Box display="flex" gap="24px" padding="0px 20px">
            {navItems.map((item) => {
              const { label } = item
              return <NavItem key={label} color="white" {...item} />
            })}
          </Box>

          {actions.map((item) => {
            const { label, onClick: actionOnClick, variant } = item
            return (
              <TrackedButton
                key={label}
                variant={variant as any}
                size="small"
                onClick={actionOnClick}
              >
                {label}
              </TrackedButton>
            )
          })}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ExchangeNavbarDesktop
