import React from 'react'
import { SubHeader as FlockSubHeader } from '@flock/flock-component-library'
import { ClipboardText, UserCircle, Money, File } from 'phosphor-react'
import { navigate, WindowLocation } from '@reach/router'

import {
  FUND_MANAGEMENT_URL,
  INVESTOR_MANAGEMENT_URL,
  SALES_BASE_URL,
  SALES_LEAD_MANAGEMENT_URL,
  DISTRIBUTIONS_MANAGEMENT_URL,
  EXCHANGE_URL,
  ORDER_ONBOARDING_URL,
  NEW_CONTRIBUTIONS_URL,
  ADDRESS_SEARCH_URL,
  SALES_LEAD_DOCUMENTS_CENTER_URL,
  REPORTS_URL,
  HOME_OPS_CUSTOMER_URL,
} from '../constants'

type SubHeaderProps = {
  location?: WindowLocation
}

const SubHeader = (props: SubHeaderProps) => {
  const { location } = props
  const salesConfigs = [
    {
      text: 'Lead Management',
      onClick: () => {
        navigate(SALES_LEAD_MANAGEMENT_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: SALES_LEAD_MANAGEMENT_URL,
    },
    {
      text: 'Lead Documents',
      onClick: () => {
        navigate(SALES_LEAD_DOCUMENTS_CENTER_URL)
      },
      ImageComponent: <File weight="bold" />,
      location: SALES_LEAD_DOCUMENTS_CENTER_URL,
    },
    {
      text: 'Customer Management',
      onClick: () => {
        navigate(HOME_OPS_CUSTOMER_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: HOME_OPS_CUSTOMER_URL,
    },
  ]

  const fundManagementConfigs = [
    {
      text: 'Investors',
      onClick: () => {
        navigate(INVESTOR_MANAGEMENT_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: INVESTOR_MANAGEMENT_URL,
    },
    {
      text: 'Reporting',
      onClick: () => {
        navigate(REPORTS_URL)
      },
      ImageComponent: <Money weight="bold" />,
      location: REPORTS_URL,
    },
    {
      text: 'Distributions',
      onClick: () => {
        navigate(DISTRIBUTIONS_MANAGEMENT_URL)
      },
      ImageComponent: <Money weight="bold" />,
      location: DISTRIBUTIONS_MANAGEMENT_URL,
    },
  ]

  const orderOnboardingConfigs = [
    {
      text: 'New Contributions',
      onClick: () => {
        navigate(NEW_CONTRIBUTIONS_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: NEW_CONTRIBUTIONS_URL,
    },
    {
      text: 'Orders',
      onClick: () => {
        navigate(ORDER_ONBOARDING_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: ORDER_ONBOARDING_URL,
    },
  ]

  const addressSearchConfigs = [
    {
      text: 'Addresses',
      onClick: () => {
        navigate(ADDRESS_SEARCH_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: ADDRESS_SEARCH_URL,
    },
  ]

  let configs = salesConfigs
  if (typeof window !== 'undefined') {
    const url = window.location.href
    if (url.includes(FUND_MANAGEMENT_URL)) {
      configs = fundManagementConfigs
    } else if (url.includes(EXCHANGE_URL)) {
      configs = orderOnboardingConfigs
    } else if (url.includes(SALES_BASE_URL)) {
      configs = salesConfigs
    } else if (url.includes(ADDRESS_SEARCH_URL)) {
      configs = addressSearchConfigs
    }
  }

  let selectedTab = configs.findIndex((config) =>
    location?.href?.includes(config.location)
  )
  if (selectedTab === -1) {
    selectedTab = 0
  }

  return <FlockSubHeader selectedTab={selectedTab} menuItemConfigs={configs} />
}

SubHeader.defaultProps = {
  location: null,
}

export default SubHeader
