import { useMutation } from '@apollo/client'
import { InputType } from '@flock/shared-ui'
import { useSnackbar } from '@flock/flock-component-library'
import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  AdminGetCustomerDocument,
  AdminUpdateCustomerDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import GridFormModal from '../shared/GridFormModal'

// yup validation overrides the rules of react-hook-form
// including when an input config is required, so should define required here
const validationSchema = Yup.object().shape({
  overrideOnboardingFee: Yup.boolean(),
  feeOverride: Yup.string().when(
    ['overrideOnboardingFee', 'waiveOnboardingFee'],
    {
      is: (overrideOnboardingFee: boolean, waiveOnboardingFee: boolean) =>
        overrideOnboardingFee && !waiveOnboardingFee,
      then: Yup.string()
        .required('Required field')
        .matches(/^0.0[0-6](\d+)?$/, 'Fee should be less than 0.07'),
    }
  ),
  brokerCommission: Yup.string().when('overrideBrokerCommission', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Commission should be a decimal representing a percentage of the offer price.'
      ),
  }),
  closingCosts: Yup.string().when('overrideClosingCosts', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Closing costs should be a decimal representing a percentage of the offer price.'
      ),
  }),
  customerNameOverride: Yup.string().when('overrideCustomerName', {
    is: true,
    then: Yup.string().required('Customer name is required'),
  }),
  // https://github.com/jquense/yup/issues/549#issuecomment-855698808
  // value is number if numbers exist in it, string if you click on textfield but is empty,
  // null if you don't interact with it at all
  remodelCosts: Yup.string().when('setRemodelCosts', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  cashTakeout: Yup.string().when('overrideCashTakeout', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  rentReduction: Yup.string().when('overrideRentReduction', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  daysInRemodelDeduction: Yup.string().when('overrideDaysInRemodelDeduction', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  offerDisclaimer: Yup.string().when('overrideOfferDisclaimer', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  addressDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
  cityStateZipDisplayOverride: Yup.string().when('overrideAddress', {
    is: true,
    then: Yup.string().required('Required field'),
  }),
})

const inputConfigs = [
  {
    name: 'overrideCustomerName',
    type: InputType.Checkbox,
    props: {
      label: 'Override Customer Name',
    },
  },
  {
    name: 'customerNameOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideCustomerName,
    props: {
      label: 'Customer Name Override',
      format: 'text',
    },
  },
  // {
  //   name: 'overridePortfolioAssetCount',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Portfolio Asset Count',
  //   },
  // },
  // {
  //   name: 'portfolioAssetCountOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) =>
  //     !!watchedFields.overridePortfolioAssetCount,
  //   props: {
  //     label: 'Portfolio Asset Count Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overridePortfolioDoorCount',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Portfolio Door Count',
  //   },
  // },
  // {
  //   name: 'portfolioDoorCountOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) =>
  //     !!watchedFields.overridePortfolioDoorCount,
  //   props: {
  //     label: 'Portfolio Door Count Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overridePortfolioCityState',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Portfolio City, State',
  //   },
  // },
  // {
  //   name: 'portfolioCityStateOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) =>
  //     !!watchedFields.overridePortfolioCityState,
  //   props: {
  //     label: 'Portfolio City, State Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overridePortfolioSFRDoors',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Portfolio SFR Doors',
  //   },
  // },
  // {
  //   name: 'portfolioSFRDoorsOverride',
  //   type: InputType.Text,
  //   watchField: 'overridePortfolioSFRDoors',
  //   renderIf: (watchedFields: any) => !!watchedFields.overridePortfolioSFRDoors,
  //   props: {
  //     label: 'Portfolio SFR Doors Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overridePortfolioMultifamilyDoors',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Portfolio Multifamily Doors',
  //   },
  // },
  // {
  //   name: 'portfolioMultifamilyDoorsOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) =>
  //     !!watchedFields.overridePortfolioMultifamilyDoors,
  //   props: {
  //     label: 'Portfolio Multifamily Doors Override',
  //     format: 'text',
  //   },
  // },
  {
    name: 'setCapexCosts',
    type: InputType.Checkbox,
    props: {
      label: 'Set Capex Costs',
    },
  },
  // {
  //   name: 'capexCosts',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.setCapexCosts,
  //   props: {
  //     label: 'Capex Costs',
  //     format: 'number',
  //   },
  // },
  // {
  //   name: 'setRemodelCosts',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Set Remodel Costs',
  //   },
  // },
  // {
  //   name: 'remodelCosts',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.setRemodelCosts,
  //   props: {
  //     label: 'Remodel Costs',
  //     format: 'number',
  //   },
  // },
  // {
  //   name: 'overrideNetYield',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Net Yield',
  //   },
  // },
  // {
  //   name: 'netYieldOverride',
  //   type: InputType.Text,
  //   watchField: 'overrideNetYield',
  //   props: {
  //     label: 'Net Yield',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overrideHoldingPeriod',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Holding Period',
  //   },
  // },
  // {
  //   name: 'holdingPeriodOverride',
  //   type: InputType.Text,
  //   watchField: 'overrideHoldingPeriod',
  //   props: {
  //     label: 'Holding Period',
  //     format: 'number',
  //   },
  // },
  {
    name: 'overrideMortgage',
    type: InputType.Checkbox,
    props: {
      label: 'Override Mortgage',
    },
  },
  {
    name: 'mortgageOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideMortgage,
    props: {
      label: 'Mortgage Override',
      format: 'number',
    },
  },
  // {
  //   name: 'showCashFlowDeductions',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Show Cash Flow Deductions',
  //   },
  // },
  // {
  //   name: 'hideRemodelCosts',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Hide Remodel Costs on Offer Page',
  //   },
  // },
  {
    name: 'overrideOnboardingFee',
    type: InputType.Checkbox,
    props: {
      label: 'Override Onboarding Fee',
    },
  },
  {
    name: 'waiveOnboardingFee',
    type: InputType.Checkbox,
    renderIf: (watchedFields: any) => !!watchedFields.overrideOnboardingFee,
    props: {
      label: 'Waive Onboarding Fee',
    },
  },
  {
    name: 'feeOverride',
    type: InputType.Text,
    renderIf: (watchedFields: any) =>
      !!watchedFields.overrideOnboardingFee &&
      !watchedFields.waiveOnboardingFee,
    props: {
      label: 'Fee Override (enter as a decimal)',
      format: 'text',
    },
  },
  {
    name: 'overrideBrokerCommission',
    type: InputType.Checkbox,
    props: {
      label: 'Override Broker Commission',
    },
  },
  {
    name: 'brokerCommission',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideBrokerCommission,
    props: {
      label: 'Broker Commission (enter as a decimal)',
      format: 'text',
    },
  },
  {
    name: 'overrideClosingCosts',
    type: InputType.Checkbox,
    props: {
      label: 'Override Closing Costs',
    },
  },
  {
    name: 'closingCosts',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideClosingCosts,
    props: {
      label: 'Closing Costs (enter as a decimal)',
      format: 'text',
    },
  },

  {
    name: 'overrideCashTakeout',
    type: InputType.Checkbox,
    props: {
      label: 'Override Cash Takeout',
    },
  },
  {
    name: 'cashTakeout',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideCashTakeout,
    props: {
      label: 'Cash Takeout',
      format: 'number',
    },
  },
  {
    name: 'overrideRentReduction',
    type: InputType.Checkbox,
    props: {
      label: 'Override Rent Reduction',
    },
  },
  {
    name: 'rentReduction',
    type: InputType.Text,
    renderIf: (watchedFields: any) => !!watchedFields.overrideRentReduction,
    props: {
      label: 'Rent Reduction',
      format: 'number',
    },
  },
  {
    name: 'overrideDaysInRemodelDeduction',
    type: InputType.Checkbox,
    props: {
      label: 'Override Days in Remodel Deduction',
    },
  },
  {
    name: 'daysInRemodelDeduction',
    type: InputType.Text,
    renderIf: (watchedFields: any) =>
      !!watchedFields.overrideDaysInRemodelDeduction,
    props: {
      label: 'Days In Remodel Deduction',
      format: 'number',
    },
  },
  // {
  //   name: 'overrideOfferDisclaimer',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Offer Disclaimer',
  //   },
  // },
  // {
  //   name: 'offerDisclaimer',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.overrideOfferDisclaimer,
  //   props: {
  //     label: 'Offer Disclaimer Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overrideAddress',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Address',
  //   },
  // },
  // {
  //   name: 'addressDisplayOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.overrideAddress,
  //   props: {
  //     label: 'Street Address Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'cityStateZipDisplayOverride',
  //   type: InputType.Text,
  //   renderIf: (watchedFields: any) => !!watchedFields.overrideAddress,
  //   props: {
  //     label: 'City, State, Zip Display Override',
  //     format: 'text',
  //   },
  // },
  // {
  //   name: 'overrideGraphYears',
  //   type: InputType.Checkbox,
  //   props: {
  //     label: 'Override Years to Project',
  //   },
  // },
  // {
  //   name: 'yearsToProject',
  //   type: InputType.Text,
  //   watchField: 'overrideGraphYears',
  //   props: {
  //     label: 'Years to Project on Scenario Analysis',
  //     format: 'number',
  //   },
  // },
]

type EditCustomerOverridesParams = {
  waiveOnboardingFee: boolean
  overrideOnboardingFee: boolean
  overrideCustomerStatus: boolean
  customerStatusOverride: string
  overrideCustomerName: boolean
  customerNameOverride: string
  setCapexCosts: boolean
  capexCosts: string
  setRemodelCosts: boolean
  remodelCosts: string
  feeOverride: string
  overrideAddress: boolean
  addressDisplayOverride: string
  cityStateZipDisplayOverride: boolean
  overrideGraphYears: boolean
  yearsToProject: number

  showCashFlowDeductions: boolean
  overrideOfferDisclaimer: boolean
  offerDisclaimer: string
  overrideNetYield: boolean
  netYieldOverride: string
  overrideHoldingPeriod: boolean
  holdingPeriodOverride: number
  overrideMortgage: boolean
  mortgageOverride: number
  hideRemodelCosts: boolean
  overrideCashTakeout: boolean
  cashTakeout: string
  overrideClosingCosts: boolean
  closingCosts: string
  overrideBrokerCommission: boolean
  brokerCommission: string
  overrideRentReduction: boolean
  rentReduction: string
  overrideDaysInRemodelDeduction: boolean
  daysInRemodelDeduction: string
  portfolioAssetCountOverride: string
  portfolioDoorCountOverride: string
  portfolioCityStateOverride: string
  portfolioSFRDoorsOverride: string
  portfolioMultifamilyDoorsOverride: string
  overridePortfolioAssetCount: boolean
  overridePortfolioDoorCount: boolean
  overridePortfolioCityState: boolean
  overridePortfolioSFRDoors: boolean
  overridePortfolioMultifamilyDoors: boolean
}

type EditCustomerOverridesModalProps = {
  open: boolean
  onClose: () => void
  customerUuid: string
  overrides: { [key: string]: any }
}

const EditCustomerOverridesModal = (props: EditCustomerOverridesModalProps) => {
  const { open, onClose, customerUuid, overrides } = props
  const [loading, setLoading] = useState(false)
  const [updateCustomer] = useMutation(AdminUpdateCustomerDocument)

  const { notify } = useSnackbar()

  const onSubmit = async (editOverridesParams: EditCustomerOverridesParams) => {
    const {
      waiveOnboardingFee,
      overrideOnboardingFee,
      feeOverride,
      overrideCustomerStatus,
      customerStatusOverride,
      overrideCustomerName,
      customerNameOverride,
      setCapexCosts,
      capexCosts,
      setRemodelCosts,
      remodelCosts,
      overrideAddress,
      addressDisplayOverride,
      cityStateZipDisplayOverride,
      overrideGraphYears,
      yearsToProject,

      overrideOfferDisclaimer,
      offerDisclaimer,
      overrideCashTakeout,
      cashTakeout,
      overrideNetYield,
      netYieldOverride,
      overrideHoldingPeriod,
      holdingPeriodOverride,
      overrideMortgage,
      mortgageOverride,
      overrideRentReduction,
      rentReduction,
      overrideDaysInRemodelDeduction,
      daysInRemodelDeduction,
      overrideClosingCosts,
      closingCosts,
      overrideBrokerCommission,
      brokerCommission,
      showCashFlowDeductions,
      hideRemodelCosts,
      portfolioAssetCountOverride,
      portfolioDoorCountOverride,
      portfolioCityStateOverride,
      portfolioSFRDoorsOverride,
      portfolioMultifamilyDoorsOverride,
      overridePortfolioAssetCount,
      overridePortfolioDoorCount,
      overridePortfolioCityState,
      overridePortfolioSFRDoors,
      overridePortfolioMultifamilyDoors,
    } = editOverridesParams

    const result: { [key: string]: any } = {
      ...overrides,
      waiveOnboardingFee,
      overrideOnboardingFee,
      overrideAddress,
      overrideGraphYears,
      offerDisclaimer,
      overrideCashTakeout,
      overrideRentReduction,
      overrideDaysInRemodelDeduction,
      overrideOfferDisclaimer,
      overrideClosingCosts,
      overrideBrokerCommission,
      overrideCustomerStatus,
      overrideCustomerName,
      overrideNetYield,
      overrideHoldingPeriod,
      overrideMortgage,
    }

    if (overrideOnboardingFee && !waiveOnboardingFee) {
      result.feeOverride = parseFloat(feeOverride)
    }

    if (overrideCashTakeout) {
      result.cashTakeout = parseInt(cashTakeout, 10)
    }

    if (overrideHoldingPeriod) {
      result.holdingPeriodOverride = holdingPeriodOverride
    }

    if (overrideMortgage) {
      result.mortgageOverride = mortgageOverride
    }

    if (overrideRentReduction) {
      result.rentReduction = parseInt(rentReduction, 10)
    }

    result.showCashFlowDeductions = showCashFlowDeductions
    result.hideRemodelCosts = hideRemodelCosts

    if (overrideDaysInRemodelDeduction) {
      result.daysInRemodelDeduction = parseInt(daysInRemodelDeduction, 10)
    }

    if (overrideClosingCosts) {
      result.closingCosts = parseFloat(closingCosts)
    }

    if (overrideBrokerCommission) {
      result.brokerCommission = parseFloat(brokerCommission)
    }

    if (overrideNetYield) {
      result.netYieldOverride = parseFloat(netYieldOverride)
    }

    if (overrideCustomerStatus) {
      result.customerStatusOverride = customerStatusOverride
    }

    if (overrideCustomerName) {
      result.customerNameOverride = customerNameOverride
    }

    if (overridePortfolioAssetCount) {
      result.portfolioAssetCount = parseInt(portfolioAssetCountOverride, 10)
    }

    if (overridePortfolioDoorCount) {
      result.portfolioDoorCount = parseInt(portfolioDoorCountOverride, 10)
    }

    if (overridePortfolioCityState) {
      result.portfolioCityState = portfolioCityStateOverride
    }

    if (overridePortfolioSFRDoors) {
      result.portfolioSFRDoors = parseInt(portfolioSFRDoorsOverride, 10)
    }

    if (overridePortfolioMultifamilyDoors) {
      result.portfolioMultifamilyDoors = parseInt(
        portfolioMultifamilyDoorsOverride,
        10
      )
    }

    if (setCapexCosts) {
      result.capexCosts = parseInt(capexCosts, 10)
    }

    if (setRemodelCosts) {
      result.remodelCosts = parseInt(remodelCosts, 10)
    }

    if (overrideAddress) {
      result.addressDisplayOverride = addressDisplayOverride
      result.cityStateZipDisplayOverride = cityStateZipDisplayOverride
    }

    if (overrideGraphYears) {
      result.yearsToProject = yearsToProject
    }

    setLoading(true)
    try {
      await updateCustomer({
        variables: {
          input: {
            customerUuid,
            overrides: JSON.stringify(result),
          },
        },
        refetchQueries: [AdminGetCustomerDocument],
      })
      notify('Successfully updated customer overrides.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update customer overrides.', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      open={open}
      onClose={onClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Edit customer overrides"
      formProps={{
        defaultValues: {
          ...overrides,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
      }}
      inputConfigs={inputConfigs}
    />
  )
}

export default EditCustomerOverridesModal
