import React from 'react'
import { CloseIcon, GridForm, GridFormProps } from '@flock/shared-ui'
import { Box, IconButton, Modal, Typography } from '@mui/material'

type GridFormModalProps = GridFormProps & {
  title: string
  open: boolean
  onClose: () => void
}

const GridFormModal = (props: GridFormModalProps) => {
  const { title, open, onClose, ...gridFormProps } = props
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        sx={{
          position: 'absolute',
          top: '30px',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          width: '500px',
          backgroundColor: 'gray1.main',
          borderRadius: '24px',
          boxShadow: '0px 8px 24px 0px #45494D14',
          p: '32px',
          overflowY: 'scroll',
          maxHeight: '90vh',
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '32px', right: '32px' }}
        >
          <CloseIcon />
        </IconButton>
        <GridForm ctaText="Submit" {...gridFormProps} />
      </Box>
    </Modal>
  )
}

export default GridFormModal
