import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  SnackbarProvider,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '@flock/flock-component-library'
import {
  ExchangeNavbar,
  NavLink,
  PageContainer,
  SnackbarProvider as SharedUiSnackbarProvider,
  useFundConfig,
} from '@flock/shared-ui'
import {
  Router,
  Redirect,
  Location,
  // RouteComponentProps,
  // navigate,
} from '@reach/router'
import { Box, CircularProgress, styled } from '@mui/material'
import { useFirebaseAuth } from '@flock/utils'

import {
  FLOCK_LANDING_URL,
  SALES_LEAD_MANAGEMENT_URL,
  SALES_LEAD_DOCUMENTS_CENTER_URL,

  // INVESTOR_MANAGEMENT_URL,
  // ORDER_ONBOARDING_URL,
  // DISTRIBUTIONS_MANAGEMENT_URL,
  // NEW_CONTRIBUTIONS_URL,
  // REPORTS_URL,
  // NEW_ORDER_CREATION_URL,
  LOGOUT,
  HOME_OPS_CUSTOMER_URL,
  SALES_CREATE_LEAD,
} from '../constants'
import SubHeader from '../components/SubHeader'
import LeadManagementPage from '../components/HomeOps/LeadManagementPage'
import IndividualLeadPage from '../components/HomeOps/IndividualLeadPage'
import StaticDocumentsPage from '../components/StaticDocuments/StaticDocumentsPage'
import CustomerManagementPage from '../components/HomeOps/CustomerManagementPage'
import IndividualCustomerPage from '../components/HomeOps/IndividualCustomerPage'
import CreateOperatorModal from '../components/CreateOperatorModal'
import CreateLeadPage from '../components/HomeOps/CreateLeadPage'
// import InvestorManagementPage from '../components/InvestorManagement/InvestorManagementPage'
// import IndividualInvestorPage from '../components/InvestorManagement/IndividualInvestorPage'
// import OrderTaskPage from '../components/OrderOnboarding/OrderTaskPage'
// import OrderOnboardingV3Page from '../components/OrderV3/OrderOnboardingV3Page'
// import NewContributionsQueueV2 from '../components/OrderOnboardingV2/NewContributionsQueueV2'
// import IndividualLegalEntityPage from '../components/InvestorManagement/IndividualLegalEntityPage'
// import ReportsPage from '../components/InvestorManagement/ReportsPage'
// import CreateOrderPage from '../components/CreateOrder/CreateOrderPage'
// import IndividualOrderPageV3 from '../components/OrderV3/IndividualOrderPageV3'
// import DistributionsManagementPage from '../components/InvestorManagement/DistributionsManagementPage/DistributionsManagementPage'

const navItems = [
  // {
  //   label: 'Sales',
  //   to: SALES_LEAD_MANAGEMENT_URL,
  // },
  // {
  //   label: 'Exchange',
  //   to: ORDER_ONBOARDING_URL,
  // },
  // {
  //   label: 'Fund Management',
  //   to: INVESTOR_MANAGEMENT_URL,
  // },
  { label: 'Create Operator', to: '/app/create-operator' },
  {
    label: 'Logout',
    to: LOGOUT,
  },
]

const mobileNavItems = (
  <>
    <NavLink color="white.main" to={SALES_LEAD_MANAGEMENT_URL}>
      Sales
    </NavLink>
    {/* <NavLink color="white.main" to={ORDER_ONBOARDING_URL}>
      Exchange
    </NavLink>
    <NavLink color="white.main" to={INVESTOR_MANAGEMENT_URL}>
      Fund Management
    </NavLink> */}
    <NavLink color="white.main" to={LOGOUT}>
      Log Out
    </NavLink>
  </>
)

const TopBorderWrapper = styled('div')({
  borderTop: '0.0625rem solid black',
})

const ContentContainer = styled('div')({
  maxWidth: '71.85rem',
  margin: 'auto',
  paddingBottom: '48px',

  [TABLET_BREAKPOINT]: {
    margin: '2rem',
  },

  [MOBILE_BREAKPOINT]: {
    margin: '1rem',
  },
})

const LogoutPage = () => {
  const { logout } = useFirebaseAuth()
  useEffect(() => {
    logout()
  }, [logout])
  return null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const InvestorManagement = (_: RouteComponentProps) => (
//   <InvestorManagementPage />
// )

const App = () => {
  const { fundName } = useFundConfig()
  const headerNavItems = navItems
  const headerMobileNavItems = mobileNavItems

  const { user, useValidateLoggedInOrRedirect } = useFirebaseAuth()

  useValidateLoggedInOrRedirect()

  if (!user) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <SnackbarProvider>
      <SharedUiSnackbarProvider>
        <PageContainer title={`Admin | ${fundName}`} defaultFont="degular">
          <Helmet>
            <link rel="canonical" href={FLOCK_LANDING_URL} />
          </Helmet>
          <ExchangeNavbar
            logoPath={SALES_LEAD_MANAGEMENT_URL}
            navItems={headerNavItems}
            actions={[]}
            mobileNavItems={headerMobileNavItems}
          />
          <Location>
            {(locationProps) => (
              <>
                <SubHeader location={locationProps.location} />
                <TopBorderWrapper>
                  <ContentContainer>
                    <Router>
                      <StaticDocumentsPage
                        path={SALES_LEAD_DOCUMENTS_CENTER_URL}
                      />
                      <LeadManagementPage path={SALES_LEAD_MANAGEMENT_URL} />
                      <IndividualLeadPage
                        path={`${SALES_LEAD_MANAGEMENT_URL}/:leadUuid`}
                      />
                      <CreateLeadPage path={SALES_CREATE_LEAD} />

                      <CustomerManagementPage path={HOME_OPS_CUSTOMER_URL} />
                      <IndividualCustomerPage
                        path={`${HOME_OPS_CUSTOMER_URL}/:customerUuid`}
                      />
                      {/* 
                      <InvestorManagement path={INVESTOR_MANAGEMENT_URL} />
                      <ReportsPage path={REPORTS_URL} />
                      <IndividualInvestorPage
                        path={`${INVESTOR_MANAGEMENT_URL}/:investorAccountUuid`}
                      />
                      <IndividualLegalEntityPage
                        path={`${INVESTOR_MANAGEMENT_URL}/:investorAccountUuid/legal-entity-management/:legalEntityUuid`}
                      />
                      <NewContributionsQueueV2 path={NEW_CONTRIBUTIONS_URL} />
                      <OrderOnboardingV3Page path={ORDER_ONBOARDING_URL} />
                      <IndividualOrderPageV3
                        path={`${ORDER_ONBOARDING_URL}/:orderUuid`}
                      />
                      <OrderTaskPage
                        path={`${ORDER_ONBOARDING_URL}/:orderUuid/tasks/:taskUuid`}
                      />
                      <CreateOrderPage
                        path={`${NEW_ORDER_CREATION_URL}/:taskUuid`}
                      />
                      <DistributionsManagementPage
                        path={DISTRIBUTIONS_MANAGEMENT_URL}
                      /> */}
                      <CreateOperatorModal path="/app/create-operator" />

                      <LogoutPage path={LOGOUT} />
                      <Redirect
                        from="/*"
                        to={SALES_LEAD_MANAGEMENT_URL}
                        noThrow
                      />
                    </Router>
                  </ContentContainer>
                </TopBorderWrapper>
              </>
            )}
          </Location>
        </PageContainer>
      </SharedUiSnackbarProvider>
    </SnackbarProvider>
  )
}

export default App
