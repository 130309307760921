import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, ThemeProvider, Typography } from '@mui/material'
import {
  AnyInputConfig,
  GridForm,
  InputType,
  useFundConfig,
} from '@flock/shared-ui'
import { useSnackbar } from '@flock/flock-component-library'
import { useMutation, useQuery } from '@apollo/client'
import {
  AdminAdminCreateLeadDocument,
  AdminGetOperatorDocument,
  AdminGetOperatorsDocument,
  Core_Operator,
  Core_LeadAttribution,
  Core_TransactionType,
  Core_GetOperatorsResponse,
  AdminSearchLeadsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps } from '@reach/router'
import { GOOGLE_MAPS_API_KEY, SALES_LEAD_MANAGEMENT_URL } from '../../constants'

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

type CreateAdminLeadInputs = {
  fullName: string
  phoneNumber: string
  email: string
  attribution: Core_LeadAttribution
  willReceiveMQLAutomationChecked: boolean
  willReceiveMQLAutomationNotChecked: boolean
  willReceiveAgentAutomation: boolean
  willReceiveOfferAutomation: boolean
  willReceiveWelcomeEmail: boolean
  transactionType: Core_TransactionType
  defaultOnboardingFee: number
  portfolioOnboardingFee: number
  brokerOnboardingFee: number
  investorAccredited: boolean
  addressInfo: any[]
  salesAssignee?: string
  createValuationTask: boolean
  valuationTaskDeadline?: Date
}

type AddressData = {
  streetAddress?: string
  city: string
  unit: string
  state: string
  zipcode: string
  county: string
  streetNumber: string
  formattedAddress?: string
  latitude: number
  longitude: number
  lat?: number
  lng?: number
}

type LeadAddress = {
  address: AddressData
  formattedAddress: string
  homeDetails: LeadHomeDetails[]
  propertyDetails: PropertyDetails
  transactionType: Core_TransactionType
}

type LeadHomeDetails = {
  bedrooms: number
  fullBathrooms: number
  // halfBathrooms: number
  basement: string
  squareFootage: number
  yearBuilt: number
  name: string
  leadLeaseDetails?: LeadLeaseDetails
}

type LeadLeaseDetails = {
  rentAmount?: number
  leaseType?: string
  leaseEndDate?: string
}

type PropertyDetails = {
  yearBuilt: number
  propertyType: string
  condition: string
  mortgageAmount: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateLeadPage = (_: RouteComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [adminCreateLead] = useMutation(AdminAdminCreateLeadDocument)
  const { adminTheme } = useFundConfig()
  const { notify } = useSnackbar()
  const newDate = new Date()
  const tempDate = new Date(newDate.setDate(newDate.getDate() + 1))
  const { loading: operatorsLoading, data: operatorsData } = useQuery(
    AdminGetOperatorsDocument,
    {
      variables: {},
      onError: () => {
        notify('Failed to get sales assignees', 'error')
      },
    }
  )

  const { defaultOnboardingFee } = useFundConfig()

  let operatorsDropdownOptions: any[] = []
  if (!operatorsLoading) {
    const { operators } = operatorsData?.operators as Core_GetOperatorsResponse
    operatorsDropdownOptions = operators.map((operator: Core_Operator) => ({
      value: operator.uuid,
      label: operator.fullName,
    }))
  }

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'transactionType',
      required: true,
      type: InputType.Dropdown,
      gridItemProps: {
        sm: 6,
      },
      props: {
        label: 'Transaction Type',
        options: [
          {
            value: Core_TransactionType.TransactionTypeSingle_721,
            label: 'Single 721',
          },
          {
            value: Core_TransactionType.TransactionTypePortfolio_721,
            label: 'Portfolio 721',
          },
        ],
      },
    },
    {
      name: 'attribution',
      required: true,
      type: InputType.Dropdown,
      gridItemProps: {
        sm: 6,
      },
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypePortfolio_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionTypeBroker,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeContactInformation,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      props: {
        label: 'Attribution',
        options: [
          {
            value: Core_LeadAttribution.LeadAttributionInternetSearch,
            label: 'Google search result',
          },
          {
            value: Core_LeadAttribution.LeadAttributionEmail,
            label: 'Email',
          },
          {
            value: Core_LeadAttribution.LeadAttributionWordOfMouth,
            label: 'Word of mouth',
          },
          {
            value: Core_LeadAttribution.LeadAttributionDirectMail,
            label: 'Direct Mail',
          },
          {
            value: Core_LeadAttribution.LeadAttributionRealtor,
            label: 'Realtor or Broker',
          },
          {
            value: Core_LeadAttribution.LeadAttributionFacebookOrInstagramAd,
            label: 'Facebook or Instagram Ad',
          },
          {
            value: Core_LeadAttribution.LeadAttributionGoogleAd,
            label: 'Google ad',
          },
          {
            value: Core_LeadAttribution.LeadAttributionTiktok,
            label: 'TikTok',
          },
          {
            value: Core_LeadAttribution.LeadAttributionNextdoorAd,
            label: 'Nextdoor Ad',
          },
          {
            value: Core_LeadAttribution.LeadAttributionRadio,
            label: 'Radio',
          },
          {
            value: Core_LeadAttribution.LeadAttributionNewspaperAd,
            label: 'Newspaper ad',
          },
          {
            value: Core_LeadAttribution.LeadAttributionBiggerpocketsNewsletter,
            label: 'Biggerpockets Newsletter',
          },
          {
            value: Core_LeadAttribution.LeadAttributionColdOutbound,
            label: 'Cold Outbound',
          },
          {
            value: Core_LeadAttribution.LeadAttributionOther,
            label: 'Other',
          },
        ],
      },
    },
    {
      name: 'fullName',
      type: InputType.Text,
      required: false,
      gridItemProps: {
        sm: 6,
      },
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypePortfolio_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionTypeBroker,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeContactInformation,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      props: {
        label: 'Full Name',
      },
    },

    {
      name: 'email',
      type: InputType.Text,
      required: false,
      gridItemProps: {
        sm: 6,
      },
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypePortfolio_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionTypeBroker,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeContactInformation,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      props: {
        label: 'Email',
        format: 'email',
      },
    },

    {
      name: 'phoneNumber',
      type: InputType.Text,
      required: false,
      gridItemProps: {
        sm: 6,
      },
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypePortfolio_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionTypeBroker,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeContactInformation,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      props: {
        format: 'phone',
        label: 'Phone Number',
      },
    },
    {
      name: 'salesAssignee',
      type: InputType.Dropdown,
      required: false,
      gridItemProps: {
        sm: 6,
      },
      defaultValue: '',
      props: {
        label: 'Sales Assignee (if not you)',
        options: operatorsDropdownOptions || [
          {
            label: 'No Operators',
            value: '',
          },
        ],
      },
    },
    {
      name: 'defaultOnboardingFee',
      type: InputType.Text,
      required: false,
      defaultValue: defaultOnboardingFee * 100,
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      gridItemProps: {
        sm: 3,
      },
      props: {
        format: 'percent',
        label: 'Onboarding Fee (%)',
      },
    },
    {
      name: 'portfolioOnboardingFee',
      type: InputType.Text,
      required: false,
      defaultValue: 4.5,
      renderIf: (watchedFields: any) =>
        [Core_TransactionType.TransactionTypePortfolio_721].includes(
          watchedFields.transactionType
        ),
      gridItemProps: {
        sm: 3,
      },
      props: {
        format: 'percent',
        label: 'Portfolio Onboarding Fee (%)',
      },
    },
    {
      name: 'brokerOnboardingFee',
      type: InputType.Text,
      required: false,
      defaultValue: 6.0,
      renderIf: (watchedFields: any) =>
        [Core_TransactionType.TransactionTypeBroker].includes(
          watchedFields.transactionType
        ),
      gridItemProps: {
        sm: 3,
      },
      props: {
        format: 'percent',
        label: 'Broker Fee (%)',
      },
    },
    {
      name: 'investorAccredited',
      type: InputType.Checkbox,
      gridItemProps: {
        sm: 3,
      },
      renderIf: (watchedFields: any) =>
        [
          Core_TransactionType.TransactionTypeSingle_721,
          Core_TransactionType.TransactionTypePortfolio_721,
          Core_TransactionType.TransactionTypeReferralDirect,
          Core_TransactionType.TransactionTypeBroker,
          Core_TransactionType.TransactionType_1031Lead,
          Core_TransactionType.TransactionTypeContactInformation,
          Core_TransactionType.TransactionTypeOther,
        ].includes(watchedFields.transactionType),
      props: {
        label: 'Investor Accredited?',
      },
    },
    {
      name: 'addressInfo',
      type: InputType.Multiform,
      props: {
        title: 'Lead Address',
        maxForms: 1000,
        minForms: 1,
        incrementText: '+ Add Address',
        decrementText: '- Remove Address',
        inputConfigs: [
          {
            name: 'addressData',
            type: InputType.Address,
            required: false,
            props: {
              googleMapsApiKey: GOOGLE_MAPS_API_KEY,
              label: 'Please enter the address',
              fullWidth: true,
            },
          },
          {
            name: 'propertyType',
            type: InputType.Dropdown,
            required: false,
            gridItemProps: {
              sm: 3,
            },
            props: {
              label: 'Property Type (please select)',
              options: [
                {
                  label: 'single-family home',
                  value: 'singlefamily',
                },
                {
                  label: 'duplex',
                  value: 'duplex',
                },
                {
                  label: 'triplex',
                  value: 'triplex',
                },
                {
                  label: 'fourplex',
                  value: 'fourplex',
                },
                {
                  label: 'attached townhouse',
                  value: 'townhouse',
                },
                {
                  label: 'condo',
                  value: 'condo',
                },
                {
                  label: 'other',
                  value: 'other',
                },
              ],
            },
          },
          {
            name: 'yearBuilt',
            required: false,
            type: InputType.Text,
            props: {
              label: 'Year Built',
              format: 'year',
            },
            gridItemProps: {
              sm: 3,
            },
          },
          {
            name: 'mortgageAmount',
            type: InputType.Text,
            required: false,
            props: {
              label: 'Mortgage Amount',
              format: 'dollars',
              placeholder: '$100,000',
            },
            gridItemProps: {
              sm: 3,
            },
          },
          {
            name: 'propertyCondition',
            type: InputType.Dropdown,
            required: false,
            props: {
              label: 'Property Condition (please select)',
              options: [
                {
                  label: '5 - Pristine',
                  value: '5 - pristine',
                },
                {
                  label: '4 - Great',
                  value: '4 - great',
                },
                {
                  label: '3 - Average',
                  value: '3 - average',
                },
                {
                  label: '2 - Below Average',
                  value: '2 - below average',
                },
                {
                  label: '1 - Requires Renovation',
                  value: '1 - requires complete renovation',
                },
              ],
            },
            gridItemProps: {
              sm: 3,
            },
          },
          {
            name: 'unitName1',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              !['singlefamily'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Unit Name',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'beds1',
            type: InputType.Text,
            required: false,
            props: {
              label: 'Beds',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'baths1',
            type: InputType.Text,
            required: false,
            props: {
              label: 'Baths',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'sqft1',
            type: InputType.Text,
            required: false,
            props: {
              label: 'Sqft',
              format: 'number',
              placeholder: '2000',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'leaseType1',
            type: InputType.Dropdown,
            required: false,
            gridItemProps: {
              sm: 2,
            },
            props: {
              label: 'Lease Type',
              options: [
                {
                  label: 'Long Term',
                  value: 'long_term',
                },
                {
                  label: 'Month-to-month',
                  value: 'mtm',
                },
                {
                  label: 'None',
                  value: 'none',
                },
              ],
            },
          },
          {
            name: 'leaseEnd1',
            type: InputType.DatePicker,
            required: false,
            props: {
              label: 'Lease End',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'rentAmount1',
            type: InputType.Text,
            required: false,
            props: {
              label: 'Rent Amount',
              format: 'dollars',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'unitName2',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Unit Name',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'beds2',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Beds',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'baths2',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Baths',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'sqft2',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Sqft',
              format: 'number',
              placeholder: '2000',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'leaseType2',
            type: InputType.Dropdown,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            gridItemProps: {
              sm: 2,
            },
            props: {
              label: 'Lease Type',
              options: [
                {
                  label: 'Long Term',
                  value: 'long_term',
                },
                {
                  label: 'Month-to-month',
                  value: 'mtm',
                },
                {
                  label: 'None',
                  value: 'none',
                },
              ],
            },
          },
          {
            name: 'leaseEnd2',
            type: InputType.DatePicker,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Lease End',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'rentAmount2',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['duplex', 'triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Rent Amount',
              format: 'dollars',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'unitName3',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Unit Name',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'beds3',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Beds',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'baths3',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Baths',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'sqft3',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Sqft',
              format: 'number',
              placeholder: '2000',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'leaseType3',
            type: InputType.Dropdown,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            gridItemProps: {
              sm: 2,
            },
            props: {
              label: 'Lease Type',
              options: [
                {
                  label: 'Long Term',
                  value: 'long_term',
                },
                {
                  label: 'Month-to-month',
                  value: 'mtm',
                },
                {
                  label: 'None',
                  value: 'none',
                },
              ],
            },
          },
          {
            name: 'leaseEnd3',
            type: InputType.DatePicker,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Lease End',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'rentAmount3',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['triplex', 'fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Rent Amount',
              format: 'dollars',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'unitName4',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Unit Name',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
          {
            name: 'beds4',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Beds',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'baths4',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Baths',
              format: 'number',
              placeholder: '2',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'sqft4',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Sqft',
              format: 'number',
              placeholder: '2000',
            },
            gridItemProps: {
              sm: 1,
            },
          },
          {
            name: 'leaseType4',
            type: InputType.Dropdown,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            gridItemProps: {
              sm: 2,
            },
            props: {
              label: 'Lease Type',
              options: [
                {
                  label: 'Long Term',
                  value: 'long_term',
                },
                {
                  label: 'Month-to-month',
                  value: 'mtm',
                },
                {
                  label: 'None',
                  value: 'none',
                },
              ],
            },
          },
          {
            name: 'leaseEnd4',
            type: InputType.DatePicker,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Lease End',
            },
            gridItemProps: {
              sm: 2,
            },
          },
          {
            name: 'rentAmount4',
            type: InputType.Text,
            required: false,
            renderIf: (watchedFields: any, multiformParentIndex?: number) =>
              ['fourplex'].includes(
                watchedFields[`propertyType${multiformParentIndex}`]
              ),
            props: {
              label: 'Rent Amount',
              format: 'dollars',
            },
            gridItemProps: {
              sm: 1.5,
            },
          },
        ],
      },
    },
  ]

  const { data: ownOperatorData } = useQuery(AdminGetOperatorDocument, {
    variables: {
      input: {},
    },
    onError: () => {
      notify('Failed to get operator data', 'error')
    },
  })

  const onSubmit = async (
    inputs: CreateAdminLeadInputs,
    reset?: () => void
  ) => {
    setLoading(true)

    // Check requirements for each transaction type
    if (
      [
        Core_TransactionType.TransactionTypeDispositionAsset,
        Core_TransactionType.TransactionTypeFundPurchasedProperty,
      ].includes(inputs.transactionType)
    ) {
      if (inputs.addressInfo[0].addressData === undefined) {
        notify('Please enter in an address', 'error')
        setLoading(false)
        return
      }
    } else if ((!inputs.email && !inputs.phoneNumber) || !inputs.fullName) {
      notify(
        'Please enter a name, and either an email or phone number',
        'error'
      )
      setLoading(false)
      return
    }

    // Set lead name
    const { fullName } = inputs

    // Set contact info/1031 leads
    const { transactionType } = inputs
    const leadAddresses: LeadAddress[] = []
    if (inputs.addressInfo[0]?.addressData) {
      for (let i = 0; i < inputs.addressInfo.length; i += 1) {
        // Get unit-specific home details
        let numUnits = 1
        if (inputs.addressInfo[i]?.propertyType === 'duplex') {
          numUnits = 2
        } else if (inputs.addressInfo[i]?.propertyType === 'triplex') {
          numUnits = 3
        } else if (inputs.addressInfo[i]?.propertyType === 'fourplex') {
          numUnits = 4
        }
        const homeDetails: LeadHomeDetails[] = []
        for (let j = 0; j < numUnits; j += 1) {
          const homeDetail = {
            bedrooms: inputs.addressInfo[i][`beds${j + 1}`],
            fullBathrooms: inputs.addressInfo[i][`baths${j + 1}`]
              ? Math.floor(inputs.addressInfo[i][`baths${j + 1}`])
              : 0,
            basement: inputs.addressInfo[i][`basement${j + 1}`],
            squareFootage: inputs.addressInfo[i][`sqft${j + 1}`],
            yearBuilt: inputs.addressInfo[i]?.yearBuilt
              ? Number(inputs.addressInfo[i]?.yearBuilt)
              : 0,
            name: inputs.addressInfo[i][`unitName${j + 1}`],
            leadLeaseDetails: {
              leaseType: inputs.addressInfo[i][`leaseType${j + 1}`],
              leaseEndDate: inputs.addressInfo[i][`leaseEnd${j + 1}`],
              rentAmount: inputs.addressInfo[i][`rentAmount${j + 1}`],
            },
          }
          homeDetails.push(homeDetail)
        }

        const { addressData } = inputs.addressInfo[i]
        const leadAddress = {
          formattedAddress: addressData?.formattedAddress || '',
          address: {
            street: addressData?.streetAddress || '',
            unit: '',
            city: addressData?.city,
            state: addressData?.state,
            zipcode: addressData?.zipcode,
            county: addressData?.county,
            streetNumber: addressData?.streetNumber,
            latitude: addressData?.lat || 0,
            longitude: addressData?.lng || 0,
          },
          propertyDetails: {
            yearBuilt: Number(inputs.addressInfo[i]?.yearBuilt) || 0,
            propertyType: inputs.addressInfo[i]?.propertyType,
            condition:
              inputs.addressInfo[i]?.propertyCondition || '3 - average', // defaults to 3 (average)
            mortgageAmount: inputs.addressInfo[i]?.mortgageAmount || 0,
          },
          homeDetails,
          transactionType,
        }

        // Append to leadAddresses
        leadAddresses.push(leadAddress)
      }
    }

    // Set onboarding fee
    let onboardingFee = inputs.defaultOnboardingFee
    if (
      inputs.transactionType ===
      Core_TransactionType.TransactionTypePortfolio_721
    ) {
      onboardingFee = inputs.portfolioOnboardingFee
    } else if (
      inputs.transactionType === Core_TransactionType.TransactionTypeBroker
    ) {
      onboardingFee = inputs.brokerOnboardingFee
    }

    // Set MQL automation
    let willReceiveMqlAutomation = inputs.willReceiveMQLAutomationNotChecked
    if (
      [
        Core_TransactionType.TransactionTypePortfolio_721,
        Core_TransactionType.TransactionTypeSingle_721,
        Core_TransactionType.TransactionTypeContactInformation,
      ].includes(inputs.transactionType)
    ) {
      willReceiveMqlAutomation = inputs.willReceiveMQLAutomationChecked
    }

    try {
      const resp = await adminCreateLead({
        variables: {
          adminCreateLeadInput: {
            name: fullName,
            email: inputs.email,
            phone: inputs.phoneNumber,
            operatorUuid: ownOperatorData?.operator?.operator?.uuid || '',
            willReceiveMqlAutomation: willReceiveMqlAutomation || false,
            willReceiveOfferAutomation:
              inputs.willReceiveOfferAutomation || false,
            willReceiveWelcomeEmail: inputs.willReceiveWelcomeEmail || false,
            willReceiveBrokerAutomation:
              inputs.willReceiveAgentAutomation || false,
            onboardingFeePercent: onboardingFee || 0,
            transactionType,
            attribution: inputs.attribution,
            investorAccredited: inputs.investorAccredited,
            salesAssigneeUuid: inputs.salesAssignee || '',
            createValuationTask: inputs.createValuationTask || false,
            valuationTaskDeadline: inputs.valuationTaskDeadline || tempDate,
            leadAddresses,
          },
        },
        refetchQueries: [AdminSearchLeadsDocument],
      })
      notify('Successfully created lead(s)', 'success')
      if (reset) {
        reset()
      }
      window.location.href = `${SALES_LEAD_MANAGEMENT_URL}/${resp?.data?.adminCreateLead?.leads?.[0]?.uuid}`
    } catch (e) {
      notify('Failed to create lead(s)', 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <PageTitleContainer>
        <Typography variant="h4">Create Lead</Typography>
      </PageTitleContainer>
      <ThemeProvider theme={adminTheme}>
        <Grid container>
          <GridForm
            onSubmit={onSubmit}
            inputConfigs={inputConfigs}
            loading={loading}
            ctaText="Create"
            gridProps={{
              spacing: 3,
            }}
            ctaBoxProps={{
              pb: '32px',
            }}
          />
        </Grid>
      </ThemeProvider>
    </>
  )
}

export default CreateLeadPage
