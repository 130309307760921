import React from 'react'
import { FirebaseAuthProvider, AuthRole } from '@flock/utils'
import { CssBaseline } from '@mui/material'
import { FundConfigProvider } from '@flock/shared-ui'

import ConfiguredApolloProvider from './apollo'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <FundConfigProvider isAdmin>
    <CssBaseline />

    <FirebaseAuthProvider authorizedRoles={[AuthRole.ADMIN]}>
      <ConfiguredApolloProvider>{element}</ConfiguredApolloProvider>
    </FirebaseAuthProvider>
  </FundConfigProvider>
)
